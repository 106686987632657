<template>
  <router-view />
</template>

<!-- eslint-disable camelcase -->
<script>
import axios from 'axios';
import getBrowserFingerprint from 'get-browser-fingerprint';
import { v4 as uuidv4 } from 'uuid';

export default {
  data() {
    return {
      deferredPrompt: null,
      showInstallPrompt: false,
      fingerprint: null,
      session_id: null,
    };
  },
  methods: {
    async initSession() {
      // eslint-disable-next-line camelcase
      const { fingerprint, session_id } = this;
      if (fingerprint == null) {
        this.fingerprint = await getBrowserFingerprint();
      }
      // eslint-disable-next-line camelcase
      if (session_id == null) {
        this.session_id = sessionStorage.getItem('session_id');
        if (this.session_id == null) {
          this.session_id = await uuidv4();
          sessionStorage.setItem('session_id', this.session_id);
        }
      }
    },
    installApp() {
      console.log('install requested');
      if (this.deferredPrompt) {
        this.deferredPrompt.prompt();
        this.deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the A2HS prompt');
          } else {
            console.log('User dismissed the A2HS prompt');
          }
          this.deferredPrompt = null;
          this.showInstallPrompt = false;
        });
      }
    },
    async asyncRegisterPageView() {
      const { data: { ip } } = await axios.get('https://api.ipify.org?format=json');
      const url = 'https://wild-turkey-trivia.debrain.cloud/api/page-view';
      // const url = 'http://localhost:3000/api/page-view';

      const location = window.location.href;
      const urlObj = new URL(location);
      const eventName = urlObj.searchParams.get('event') ?? 'default-qr';

      const payload = {
        ip,
        eventName,
        url: window.location.href,
        interaction: 'page_view_qr_page',
      };
      const config = {
        crossDomain: true,
        withCredentials: true,
      };

      const { fingerprint, session_id } = this;

      const requestConfig = { ...config };

      if (requestConfig?.headers == null) {
        requestConfig.headers = {};
      }

      requestConfig.headers.fingerprint = (requestConfig?.headers?.fingerprint != null)
        ? requestConfig.headers.fingerprint : fingerprint;
      requestConfig.headers.session_id = (requestConfig?.headers?.session_id != null)
        ? requestConfig.headers.session_id : session_id;

      const { data } = await axios.post(url, payload, requestConfig);
      return data;
    },
  },
  async mounted() {
    await this.initSession();
    await this.asyncRegisterPageView();
  },
  // created() {
  //   window.addEventListener('beforeinstallprompt', (event) => {
  //     console.log('🚀 ~ file: App.vue:44 ~ window.addEventListener ~ event:', event);
  //     event.preventDefault();
  //     this.deferredPrompt = event;
  //     this.showInstallPrompt = true;
  //   });
  // },
};
</script>

<style lang="scss">
// @import '@/styles/style.scss';

// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
// }

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
