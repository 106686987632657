<template>
  <div class="home">
    <div class="container-texts">
      <div class="contain-logo-head">
        <picture>
          <source media="(min-width: 768px)" srcset="/img/logo-head.svg"/>
          <img src="/img/logo-head-mobile.svg" alt="logo-head-mobile"/>
        </picture>
      </div>

      <div class="contain-text">
        <h1 class="principal-text">Thank you for participating</h1>

        <h2 class="second-text">
          in the Wild Turkey trivia game. <br/>
          You did great!
        </h2>

        <h2 class="third-text">
          Enjoy this offer for free shipping on <br/>
          your next Wild Turkey order.
        </h2>

        <h2 class="fourth-text">
          Use code
          <span>SHIPWILDTURKEY</span>
          during checkout <br/>
          through 12/31/24 must be 21+
        </h2>
      </div>
    </div>

    <div class="container-image">
      <picture>
        <source
          media="(min-width: 768px)"
          srcset="/img/wt-turkey-tan-for-dark-bkgd.svg"
        />
        <img
          src="/img/wt-turkey-tan-for-dark-bkgd-mobile.svg"
          alt="wt-turkey-tan-for-dark-bkgd-mobile"
        />
      </picture>
    </div>

    <div class="footer">
      <h3 class="principal">NEVER COMPROMISE. DRINK RESPONSIBLY.</h3>
      <h4 class="secondary">
        WILD TURKEY® Kentucky Straight Bourbon Whiskey, 50.5% alc./vol. (101 Proof).
        Distilled and Bottled by Wild Turkey Distilling
        Co., Lawrenceburg, KY ©2024 Campari America, New York, NY.
      </h4>
    </div>
  </div>
</template>

<script>
// import axios from 'axios';

import API from '../../utils/api';

export default {
  name: 'HomeView',
  components: {},
  data() {
    return {};
  },
  methods: {
    async testAPI() {
      console.log('Testing API...');
      const { data } = await API.get('/test');
      console.log(data);
    },
    async log(message) {
      console.log('Testing API DB...');
      const payload = {
        interaction: message,
      };
      const { data } = await API.post('/log', payload);
      console.log(data);
    },
    track() {
      console.log(process.env.VUE_APP_PRUEBA);
      this.$gtag.event('btn_here_click', {
        event_category: 'click',
        event_label: 'btn_here_click',
        value: 0,
      });
    },
  },
  async beforeMount() {
    // this.testAPI();
    // this.log('Loaded application...');
  },
  mounted() {
    const currentUrl = new URL(window.location.href);

    if (currentUrl.searchParams.has('event')) {
      fetch('https://qrco.de/betv5F')
        .catch((error) => console.error('Error:', error));
    }
  },
};
</script>

<style lang="scss">
$fontBold: "Flama Condensed Bold", sans-serif;
$fontLight: "Flama Condensed Light", sans-serif;
$fontUltraLight: "Flama Condensed Ultra Light", sans-serif;
$fontMedium: "Flama Condensed Medium", sans-serif;

.home {
  background: #0a0f12;
  min-height: 100vh;
  min-width: 100dvw;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (min-width: 768px) {
    display: grid;
    // flex-direction: column;
    // flex-wrap: wrap;
  }

  .container-texts {
    // background: red;
    width: 82%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;

    @media only screen and (min-width: 768px) {
      width: 50%;
    }

    .contain-logo-head {
      @media only screen and (min-width: 768px) {
        padding-top: 4rem;
      }
    }

    .contain-text {
      display: flex;
      flex-direction: column;
      align-items: center;

      .principal-text {
        text-transform: uppercase;
        text-align: center;
        font-family: $fontMedium;
        color: #D21F31;
        font-size: 45px;
        line-height: 39.24px;
        letter-spacing: 1.5px;
        margin-bottom: 15px;
        font-weight: 550;
      }

      .second-text {
        text-transform: uppercase;
        text-align: center;
        font-family: $fontMedium;
        color: #E8E5DE;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 1.5px;
        margin-top: 0;
        font-weight: 550;
      }

      .third-text {
        text-transform: uppercase;
        text-align: center;
        font-family: $fontUltraLight;
        color: #E8E5DE;
        font-size: 22px;
        line-height: 24px;
        letter-spacing: 1.5px;
        font-weight: 300;
      }

      .fourth-text {
        text-transform: uppercase;
        text-align: center;
        font-family: $fontLight;
        color: #E8E5DE;
        font-size: 24px;
        line-height: 28px;
        letter-spacing: 1.5px;
        font-weight: 500;

        span {
          color: #D21F31;
          font-family: $fontMedium;
          font-weight: 550;
        }
      }

      @media only screen and (min-width: 768px) {
        .principal-text {
          font-size: 50px;
          line-height: 60px;
          letter-spacing: 4px;
        }
        .second-text {
          font-size: 30px;
          line-height: 40px;
          letter-spacing: 2px;
        }
        .second-text {
          font-size: 30px;
          line-height: 40px;
          letter-spacing: 2px;
        }
        .fourth-text {
          font-size: 30px;
          line-height: 42px;
          letter-spacing: 2px;
        }
      }
    }
  }

  .container-image {
    picture {
      @media only screen and (min-width: 768px) {
        img {
          position: fixed;
          bottom: 0;
          right: 0;
        }
      }

      @media only screen and (max-width: 767px) {
        img {
          display: block;
          margin: 0 auto;
        }
      }
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    bottom: 0;
    right: 0;
    margin-bottom: 3rem;
    left: 0;
    z-index: 1;
    position: fixed;
    padding: 0;

    @media only screen and (max-width: 767px) {
      position: static;
      padding: 30px 50px;
    }

    .principal,
    .secondary {
      text-align: center;
      margin: 0;
      font-size: 14px;
      line-height: 19.6px;
      letter-spacing: 0.2px;
      font-family: $fontMedium;
      font-weight: normal;
    }

    .secondary {
      font-family: $fontLight;
      font-weight: normal;
    }

    @media only screen and (max-width: 767px) {
      .principal, .secondary {
        line-height: 16px;
        letter-spacing: 0.2px;
      }

      .principal {
        margin-bottom: 7px;
      }
    }
  }
}
</style>
